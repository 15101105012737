import { ReactElement, createContext, useEffect, useState } from 'react';

import Pusher from 'pusher-js';

import { PermissionsService } from 'lib';

const PusherContext = createContext<Pusher | null>(null);

const PusherProvider = ({ children }: { children: ReactElement }): ReactElement => {
  const [pusherInstance, setPusherInstance] = useState<Pusher | null>(null);

  useEffect(() => {
    if (PermissionsService.isSuperAdmin()) return;

    const pusher = new Pusher(process.env.PUSHER_APP_KEY!, {
      cluster: process.env.PUSHER_CLUSTER!
    });

    setPusherInstance(pusher);

    return () => {
      pusher.disconnect();
    };
  }, []);

  return (
    <PusherContext.Provider value={pusherInstance}>
      {children}
    </PusherContext.Provider>
  );
};

export { PusherContext, PusherProvider };
