import moment from 'moment';

import { TimeService } from 'lib';

import { OrderStatusSlug } from 'features/orders/orders.types';

export const statusBySlug = (statusSlug: OrderStatusSlug): string => {
  return `status.slug:${statusSlug}`.trim();
};

export const deliverAtDateRange = (
  fromDay: number = 0,
  toDay: number = 0,
  timezone?: string
): string => {
  return `
    deliverAtUnix: ${TimeService.getStartOfDay(fromDay, 'unix', timezone)} TO ${TimeService.getEndOfDay(toDay, 'unix', timezone)}
  `.trim();
};

export const peakDateRange = (): string => {
  return `
    deliverAtUnix: ${moment('2025-03-29').tz('GMT').startOf('day').unix()} TO ${moment('2025-03-30').tz('GMT').startOf('day').unix()}
  `.trim();
};

export const orderedAtDateRange = (
  fromDay: number = 0,
  toDay: number = 0,
  timezone?: string
): string => {
  return `
    orderedAtUnix: ${TimeService.getStartOfDay(fromDay, 'unix', timezone)} TO ${TimeService.getEndOfDay(toDay, 'unix', timezone)}
  `.trim();
};

export const filterByStatuses = (...args: OrderStatusSlug[]): string => {
  return `
    (${args.map((orderStatusSlug: OrderStatusSlug, index): string => `${index !== 0 ? 'OR ' : ''}${statusBySlug(orderStatusSlug)}`, '').join(' ')})`.trim();
};
